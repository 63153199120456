import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { getResellerDiamondTransfersDetail } from "redux/admin/stats/statsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getResellers, getSubresellers } from "redux/admin/users/usersSlice";
import Heading from "components/Typography/Header";

const TotalDiamondPayoutsContainer = () => {
  const dispatch = useDispatch();
  const [sortedData, setSortedData] = useState([]);
  const [userType, setUserType] = useState("reseller");

  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setUTCDate(date.getUTCDate() - 1);
    return date.toISOString().slice(0, 10);
  });

  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setUTCDate(date.getUTCDate() + 1);
    return date.toISOString().slice(0, 10);
  });

  const { resellerDiamondTransfersDetail } = useSelector((state) => state.adminStats);
  const { resellers, subresellers } = useSelector((state) => state.adminUsers);


  useEffect(() => {
    
    const utcMinusThreeHours = -3 * 60 * 60 * 1000;
    const startDateTime =
      new Date(`${startDate}T00:00:00.000Z`).getTime() + utcMinusThreeHours;
    const endDateTime =
      new Date(`${endDate}T23:59:00.000Z`).getTime() + utcMinusThreeHours;

    dispatch(
      getResellerDiamondTransfersDetail({
        start: new Date(startDateTime).toISOString(),
        end: new Date(endDateTime).toISOString(),
        per_user: true,
      })
    );

    if (resellerDiamondTransfersDetail) {
      dispatch(getResellers());
      dispatch(getSubresellers());
    }
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    let filteredData = resellerDiamondTransfersDetail || [];

    if (filteredData) {
      if (userType === "all") {
        filteredData = filteredData.filter(
          (d) => d.user_type === "reseller" || d.user_type === "subreseller"
        );
      } else if (userType) {
        filteredData = filteredData.filter((d) => d.user_type === userType);
      }
    }

    const dataToSort = [...filteredData];

    dataToSort.sort((a, b) => b.total_diamond_sales - a.total_diamond_sales);

    let x = dataToSort.map((dataItem) => {
      let newName = "";
      if (dataItem.user_type === "reseller") {
        const matchingReseller = resellers?.data?.find(
          (reseller) => reseller.id === dataItem.user_id
        );
        if (matchingReseller) {
          newName = matchingReseller.name;
        }
      } else if (dataItem.user_type === "subreseller") {
        const matchingSubreseller = subresellers?.data?.find(
          (subreseller) => subreseller.id === dataItem.user_id
        );
        if (matchingSubreseller) {
          newName = matchingSubreseller.name;
        }
      }
      return {
        ...dataItem,
        user_name: newName,
      };
    });

    setSortedData(x);
  }, [resellerDiamondTransfersDetail, userType, dispatch]);

  useEffect(() => {
    sortedData.forEach(async (user) => {
      if (user.user_type === "reseller") {
        dispatch(getResellers());
      }
      if (user.user_type === "subreseller") {
        dispatch(getSubresellers());
      }
    });
  }, [sortedData, dispatch]);

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(sortedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Elmas Talep Komisyonu");

    // Dosyayı kaydet
    XLSX.writeFile(wb, "SkyPay-elmas-talep-komisyon.xlsx");
  };

  return (
    <div className="p-2 md:p-4">
     
      <div className="flex flex-nowrap overflow-x-auto pb-2 gap-2 md:gap-4">
        <Link to="/admin/total-sales">
          <Heading
            className="text-white-100 px-2 py-1 rounded-md cursor-pointer whitespace-nowrap text-sm md:text-base"
            level={5}
          >
            Satışlar
          </Heading>
        </Link>
        <Link to="/admin/total-payouts">
          <Heading
            className="text-white-100 hover:bg-primary transition-all px-2 py-1 rounded-md cursor-pointer whitespace-nowrap text-sm md:text-base"
            level={5}
          >
            Para Çekme Komisyonu
          </Heading>
        </Link>
        <Heading
          className="text-white-100 hover:bg-primary bg-primary transition-all px-2 py-1 rounded-md cursor-pointer whitespace-nowrap text-sm md:text-base"
          level={5}
        >
          Elmas Talep Komisyonu
        </Heading>
      </div>

      <div className="p-2 md:p-4 bg-black my-2 md:my-4 rounded-xl min-h-screen">
     
        <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center md:flex-wrap md:gap-2">
          <div className="flex items-center">
            <label className="text-white-100 font-semibold text-sm md:text-base">
              Filtreleme:
            </label>
            <select
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              className="rounded-lg px-2 py-1 md:px-3 md:py-2 mx-2 text-sm md:text-base w-full md:w-auto"
            >
              <option value="all">Tümü</option>
              <option value="reseller">Bayi</option>
              <option value="subreseller">Alt Bayi</option>
            </select>
          </div>
          
          <div className="flex items-center flex-wrap gap-2">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="rounded-lg px-2 py-1 md:px-3 md:py-2 text-sm md:text-base w-full md:w-auto"
            />
            <span className="text-white-100 text-xl hidden md:inline">-</span>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="rounded-lg px-2 py-1 md:px-3 md:py-2 text-sm md:text-base w-full md:w-auto"
            />
          </div>
          
          <button
            disabled={!sortedData?.length}
            onClick={exportToExcel}
            className="bg-primary text-white-100 px-3 py-2 rounded-md cursor-pointer text-sm md:text-base w-full md:w-auto mt-2 md:mt-0"
          >
            {!sortedData?.length ? "Excel'e Aktarılacak Veri Yok" : "Excel'e Aktar"}
          </button>
        </div>

      
        <div className="overflow-x-auto mt-4">
          <div className="md:hidden">
        
            {sortedData?.map((data) => (
              <div key={data.id} className="bg-gray-800 p-3 rounded-lg mb-3">
                <div className="flex justify-between mb-2">
                  <span className="text-white-100 font-medium">Adı:</span>
                  <span className="text-white-100">{data.user_name}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-white-100 font-medium">Tipi:</span>
                  <span className="text-white-100">
                    {data.user_type === "reseller"
                      ? "Bayi"
                      : data.user_type === "subreseller"
                      ? "Alt Bayi"
                      : "Kullanıcı"}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-white-100 font-medium">Komisyon:</span>
                  <span className="text-white-100">{data.total_fee}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-white-100 font-medium">Ödediği Ücret (₺):</span>
                  <span className="text-white-100">{data.total_try_sales}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-white-100 font-medium">Aldığı Elmas:</span>
                  <span className="text-white-100">{data.total_diamond_sales}</span>
                </div>
              </div>
            ))}
          </div>

    
          <div className="hidden md:block">
            <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
              <thead>
                <tr>
                  <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                    Adı
                  </th>
                  <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                    Tipi
                  </th>
                  <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                    Komisyon
                  </th>
                  <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                    Ödediği Ücret (₺)
                  </th>
                  <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
                    Aldığı Elmas
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {sortedData?.map((data) => (
                  <tr key={data.id}>
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                      {data.user_name}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-white-100">
                      {data.user_type === "reseller"
                        ? "Bayi"
                        : data.user_type === "subreseller"
                        ? "Alt Bayi"
                        : "Kullanıcı"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-white-100">
                      {data.total_fee}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-white-100">
                      {data.total_try_sales}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-white-100">
                      {data.total_diamond_sales}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalDiamondPayoutsContainer;
