import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { getTotalSalesDetail } from "redux/admin/stats/statsSlice";
import { useDispatch, useSelector } from "react-redux";
import Heading from "components/Typography/Header";

const TotalSalesContainer = () => {
  const dispatch = useDispatch();
  const [sortedData, setSortedData] = useState([]);
  const [userType, setUserType] = useState("reseller");

  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setUTCDate(date.getUTCDate() - 1);
    return date.toISOString().slice(0, 10);
  });

  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setUTCDate(date.getUTCDate() + 1);
    return date.toISOString().slice(0, 10);
  });

  const { totalSalesDetail } = useSelector((state) => state.adminStats);

  useEffect(() => {
    // 3 saatlik farkı milisaniye cinsinden hesaplayarak çıkarın.
    const utcMinusThreeHours = -3 * 60 * 60 * 1000;
    const startDateTime =
      new Date(`${startDate}T00:00:00.000Z`).getTime() + utcMinusThreeHours;
    const endDateTime =
      new Date(`${endDate}T23:59:00.000Z`).getTime() + utcMinusThreeHours;

    dispatch(
      getTotalSalesDetail({
        start: new Date(startDateTime).toISOString(),
        end: new Date(endDateTime).toISOString(),
        per_user: true,
      })
    );
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    let filteredData = totalSalesDetail || [];

    if (filteredData) {
      if (userType === "all") {
        filteredData = filteredData.filter(
          (d) => d.user_type === "reseller" || d.user_type === "subreseller"
        );
      } else if (userType) {
        filteredData = filteredData.filter((d) => d.user_type === userType);
      }
    }

    const dataToSort = [...filteredData];
    dataToSort.sort((a, b) => b.total_diamond_sales - a.total_diamond_sales);
    
    setSortedData(dataToSort);
  }, [totalSalesDetail, userType]);

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(sortedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Satışlar");

    // Dosyayı kaydet
    XLSX.writeFile(wb, "SkyPay-satışlar.xlsx");
  };

  return (
    <div className="p-2 md:p-4">
    
      <div className="flex flex-nowrap overflow-x-auto pb-2 gap-2 md:gap-4">
        <Heading
          className="text-white-100 bg-primary px-2 py-1 rounded-md cursor-pointer whitespace-nowrap text-sm md:text-base"
          level={5}
        >
          Satışlar
        </Heading>
        <Link to="/admin/total-payouts">
          <Heading
            className="text-white-100 hover:bg-primary transition-all px-2 py-1 rounded-md cursor-pointer whitespace-nowrap text-sm md:text-base"
            level={5}
          >
            Para Çekme Komisyonu
          </Heading>
        </Link>
        <Link to="/admin/total-diamond-payouts">
          <Heading
            className="text-white-100 hover:bg-primary transition-all px-2 py-1 rounded-md cursor-pointer whitespace-nowrap text-sm md:text-base"
            level={5}
          >
            Elmas Talep Komisyonu
          </Heading>
        </Link>
      </div>

      <div className="p-2 md:p-4 bg-black my-2 md:my-4 rounded-xl min-h-screen">
   
        <div className="flex flex-col md:flex-row md:items-center gap-3 mb-4">
          <div className="flex items-center">
            <label className="text-white-100 font-semibold text-sm md:text-base w-20">
              Filtreleme:
            </label>
            <select
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              className="rounded-lg px-2 py-1 md:px-3 md:py-2 text-sm md:text-base flex-grow"
            >
              <option value="all">Tümü</option>
              <option value="reseller">Bayi</option>
              <option value="subreseller">Alt Bayi</option>
            </select>
          </div>
          
          <div className="flex flex-wrap items-center gap-2">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="rounded-lg px-2 py-1 md:px-3 md:py-2 text-sm md:text-base w-full sm:w-auto"
            />
            <span className="text-white-100 text-xl hidden sm:inline">-</span>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="rounded-lg px-2 py-1 md:px-3 md:py-2 text-sm md:text-base w-full sm:w-auto"
            />
          </div>
          
          <button
            disabled={!sortedData?.length}
            onClick={exportToExcel}
            className="bg-primary text-white-100 px-3 py-2 rounded-md cursor-pointer text-sm md:text-base mt-2 md:mt-0"
          >
            {!sortedData?.length ? "Excel'e Aktarılacak Veri Yok" : "Excel'e Aktar"}
          </button>
        </div>


        <div className="hidden md:block overflow-x-auto mt-2 md:mt-4">
          <table className="min-w-full divide-y-2 divide-gray-200 text-xs md:text-sm">
            <thead className="">
              <tr>
                <th className="whitespace-nowrap text-left px-2 py-1 md:px-4 md:py-2 font-medium text-white-100">
                  Adı
                </th>
                <th className="whitespace-nowrap text-left px-2 py-1 md:px-4 md:py-2 font-medium text-white-100">
                  Tipi
                </th>
                <th className="whitespace-nowrap text-left px-2 py-1 md:px-4 md:py-2 font-medium text-white-100">
                  Total Elmas Satışı
                </th>
                <th className="whitespace-nowrap text-left px-2 py-1 md:px-4 md:py-2 font-medium text-white-100">
                  Kazanç
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {sortedData?.map((data) => (
                <tr key={data.user_id}>
                  <td className="whitespace-nowrap px-2 py-1 md:px-4 md:py-2 font-medium text-white-100">
                    {data.user_data?.name || "İsimsiz"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-1 md:px-4 md:py-2 text-white-100">
                    {data.user_type === "reseller"
                      ? "Bayi"
                      : data.user_type === "subreseller"
                      ? "Alt Bayi"
                      : "Kullanıcı"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-1 md:px-4 md:py-2 text-white-100">
                    {data.total_diamond_sales}
                  </td>
                  <td className="whitespace-nowrap px-2 py-1 md:px-4 md:py-2 text-white-100">
                    {data.total_try_sales}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

       
        <div className="md:hidden mt-4">
          {sortedData?.length === 0 ? (
            <p className="text-white-100 text-center py-4">Veri bulunamadı</p>
          ) : (
            <div className="space-y-3">
              {sortedData?.map((data) => (
                <div key={`card-${data.user_id}`} className="bg-gray-800 rounded-lg p-3">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="font-bold text-white-100">{data.user_data?.name || "İsimsiz"}</h3>
                    <span className="text-xs bg-primary text-white-100 px-2 py-1 rounded">
                      {data.user_type === "reseller"
                        ? "Bayi"
                        : data.user_type === "subreseller"
                        ? "Alt Bayi"
                        : "Kullanıcı"}
                    </span>
                  </div>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div>
                      <p className="text-gray-400">Elmas Satışı</p>
                      <p className="text-white-100 font-medium">{data.total_diamond_sales}</p>
                    </div>
                    <div>
                      <p className="text-gray-400">Kazanç</p>
                      <p className="text-white-100 font-medium">{data.total_try_sales}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TotalSalesContainer;
