import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalSalesDetail } from "redux/admin/stats/statsSlice";

const BarChart = () => {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("reseller");
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date.toISOString().slice(0, 10);
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date.toISOString().slice(0, 10);
  });

  const { totalSalesDetail } = useSelector((state) => state.adminStats);



  useEffect(() => {
    dispatch(
      getTotalSalesDetail({
        start: `${startDate}T00:30:20.969133+00:00`,
        end: `${endDate}T00:30:20.969133+00:00`,
        per_user: true,
      })
    );
  }, [dispatch, startDate, endDate]);

  return (
    <div className="w-full md:col-span-2 relative lg:h-[70vh] h-[50vh] m-auto border border-gray-800 rounded-lg bg-gray-900 flex flex-col">
      <div className="p-3 sm:p-4 border-b border-gray-800">
        <h2 className="text-xl sm:text-2xl text-center text-white-100 font-semibold">
          Bayi Satış Raporu
        </h2>
      </div>
      
      <div className="p-3 sm:p-4 bg-gray-900 border-b border-gray-800 flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3">
        <div className="flex flex-col sm:flex-row items-start sm:items-end w-full sm:w-auto gap-3">
          <div className="flex flex-col w-full sm:w-auto">
            <label className="text-xs sm:text-sm text-white-200 mb-1">Başlangıç Tarihi</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full rounded-md px-2 sm:px-3 py-1 sm:py-2 bg-gray-800 border border-gray-700 text-white-100 focus:outline-none focus:border-blue-500 text-sm"
            />
          </div>
          
          <div className="flex flex-col w-full sm:w-auto">
            <label className="text-xs sm:text-sm text-white-200 mb-1">Bitiş Tarihi</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full rounded-md px-2 sm:px-3 py-1 sm:py-2 bg-gray-800 border border-gray-700 text-white-100 focus:outline-none focus:border-blue-500 text-sm"
            />
          </div>
        </div>
        
        <div className="flex flex-col w-full sm:w-auto">
          <label className="text-xs sm:text-sm text-white-200 mb-1">Bayi Tipi</label>
          <select 
            value={userType} 
            onChange={(e) => setUserType(e.target.value)}
            className="w-full rounded-md px-2 sm:px-3 py-1 sm:py-2 bg-gray-800 border border-gray-700 text-white-100 focus:outline-none focus:border-blue-500 text-sm"
          >
            <option value="reseller">Bayiler</option>
            <option value="subreseller">Alt Bayiler</option>
            <option value="all">Tümü</option>
          </select>
        </div>
      </div>
      
      <div className="overflow-auto flex-1 p-2 sm:p-4">
        <table className="w-full bg-transparent text-white-100 border-collapse">
          <thead className="sticky -top-5 z-10">
            <tr>
              <th className="px-2 sm:px-4 py-2 sm:py-3 text-left text-xs font-medium text-white-200 uppercase tracking-wider border-b border-gray-800 bg-gray-900">
                BAYİ ADI
              </th>
              <th className="px-2 sm:px-4 py-2 sm:py-3 text-left text-xs font-medium text-white-200 uppercase tracking-wider border-b border-gray-800 bg-gray-900">
                BAYİ TİPİ
              </th>
              <th className="px-2 sm:px-4 py-2 sm:py-3 text-right text-xs font-medium text-white-200 uppercase tracking-wider border-b border-gray-800 bg-gray-900">
                ELMAS SATIŞI
              </th>
              <th className="px-2 sm:px-4 py-2 sm:py-3 text-right text-xs font-medium text-white-200 uppercase tracking-wider border-b border-gray-800 bg-gray-900">
                TL SATIŞI
              </th>
             
            </tr>
          </thead>
          <tbody>
            {totalSalesDetail && Array.isArray(totalSalesDetail) && totalSalesDetail.length > 0 ? (
              <>
                {totalSalesDetail
                  .filter(item => userType === "all" || item.user_type === userType)
                  .sort((a, b) => (b.total_diamond_sales || 0) - (a.total_diamond_sales || 0))
                  .map((item, index) => (
                    <tr 
                      key={index} 
                      className={`border-b border-gray-800 hover:bg-gray-800 ${!item.user_data?.name ? "bg-red-900 bg-opacity-20" : ""}`}
                    >
                      <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap text-white-100 text-sm">
                        {item.user_data?.name || "İsimsiz"} 
                        {!item.user_data?.name && 
                          <span className="text-xs text-red-400 ml-2">(ID: {item.user_id})</span>
                        }
                      </td>
                      <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap">
                        <span className={`px-2 py-1 text-xs rounded-md ${item.user_type === "reseller" ? "bg-blue-900" : "bg-purple-900"} text-white-100`}>
                          {item.user_type === "reseller" ? "Bayi" : "Alt Bayi"}
                        </span>
                        {item.is_self && 
                          <span className="ml-2 px-2 py-1 text-xs rounded-md bg-green-900 text-white-100">
                            Siz
                          </span>
                        }
                      </td>
                      <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap text-right text-white-100 text-sm">
                        {item.total_diamond_sales?.toLocaleString() || "0"}
                      </td>
                      <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap text-right text-white-100 text-sm">
                        {item.total_try_sales?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) || "0.00"} ₺
                      </td>
                    
                    </tr>
                  ))}
              
              </>
            ) : (
              <tr>
                <td colSpan="5" className="px-4 py-8 text-center text-white-200">
                  <div className="flex flex-col items-center justify-center">
                    <svg className="w-8 h-8 sm:w-10 sm:h-10 mb-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <p className="text-sm">Seçilen tarih aralığında veri bulunamadı</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BarChart;
