import React, { useEffect } from "react";
import OrderIndicator from "components/OrderIndicator";
import { useDispatch, useSelector } from "react-redux";
import { finalizePayouts, reset } from "redux/admin/payout/payoutSlice";
import { MdOutlineDone, MdClear } from "react-icons/md"
import { FaMoneyBill } from "react-icons/fa"
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import "moment/locale/tr";

moment.locale("tr");

const Payouts = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isFinalizePayoutSuccess,
    isFinalizePayoutError,
    isFinalizePayoutLoading,
    message
  } = useSelector((state) => state.adminPayouts);
  const handleFinalize = (id, status) => {
    dispatch(finalizePayouts({ payout_id: id, status: status }));
  };

  useEffect(() => {
    if (isFinalizePayoutSuccess) {
      toast.success("Talep Onaylandı");
      setTimeout(() => {
        navigate(0)
      }, 1000)
    }
    if (isFinalizePayoutError) {
      toast.error("Bir Hata Oluştu: "+message.toString());
      /*setTimeout(() => {
        navigate(0)
      }, 1000)*/
    }
    return () => {
      dispatch(reset());
    };
  }, [dispatch, isFinalizePayoutSuccess, isFinalizePayoutError]);

  
  const PayoutCard = ({ item }) => (
    <div className="bg-gray-800/30 rounded-lg p-3 mb-3 shadow-sm hover:bg-gray-700/30 transition-colors">
      <div className="grid grid-cols-2 gap-2 mb-2">
        <div>
          <div className="text-xs text-white-200">Tutar</div>
          <div className="font-medium text-white-100">{item.amount_try}₺</div>
        </div>
        <div>
          <div className="text-xs text-white-200">Komisyon</div>
          <div className="font-medium text-white-100">{item.fee_amount}₺</div>
        </div>
        <div>
          <div className="text-xs text-white-200">Bayi Adı</div>
          <div className="font-medium text-white-100 truncate">{item.requestor.name}</div>
        </div>
        <div>
          <div className="text-xs text-white-200">Ad Soyad</div>
          <div className="font-medium text-white-100 truncate">{item.holder_name}</div>
        </div>
        <div className="col-span-2">
          <div className="text-xs text-white-200">İban</div>
          <div className="font-medium text-sm text-white-100 truncate">{item.iban}</div>
        </div>
        <div>
          <div className="text-xs text-white-200">TCKN</div>
          <div className="font-medium text-white-100">{item.tckn}</div>
        </div>
        <div>
          <div className="text-xs text-white-200">İşlem Tarihi</div>
          <div className="font-medium text-sm text-white-100">{moment(item.created_at).format("LLL")}</div>
        </div>
        <div className="col-span-2">
          <div className="text-xs text-white-200">İşlem Durumu</div>
          <div>
            {item.status === "completed" ? (
              <OrderIndicator color="green">
                Aktarım Yapıldı.
              </OrderIndicator>
            ) : item.status === "pending_transfer" ? (
              <OrderIndicator color="yellow">
                Aktarım Bekliyor.
              </OrderIndicator>
            ) : item.status === "mico_processing" ? (
              <OrderIndicator color="yellow">
                Mico yükleniyor..
              </OrderIndicator>
            ) : item.status === "failed" ? (
              <OrderIndicator color="red">
                Aktarım Reddedildi.
              </OrderIndicator>
            ) : (
              <OrderIndicator color="red">No Data</OrderIndicator>
            )}
          </div>
        </div>
        {item.message && (
          <div className="col-span-2">
            <div className="text-xs text-white-200">Mesaj</div>
            <div className="text-xs text-white-100 break-words bg-gray-800/50 p-1.5 rounded max-h-[60px] overflow-y-auto">
              {item.message}
            </div>
          </div>
        )}
        <div className="col-span-2">
          <div className="text-xs text-white-200">Ref</div>
          <div className="font-medium text-sm text-white-100 truncate">{item.transfer_reference}</div>
        </div>
      </div>
      
      {item.status === "pending_transfer" && (
        <div className="mt-3 flex gap-2 justify-end">
          <button
            onClick={() => handleFinalize(item.id, "completed")}
            className="bg-primary text-white rounded-md p-1.5 flex items-center text-center font-semibold cursor-pointer text-sm"
            title="Onayla"
          >
            <MdOutlineDone className="mr-1" /> Onayla
          </button>
          <button
            onClick={() => handleFinalize(item.id, "failed")}
            className="bg-red-500 text-white rounded-md p-1.5 flex items-center text-center font-semibold cursor-pointer text-sm"
            title="Reddet"
          >
            <MdClear className="mr-1" /> Reddet
          </button>
          <button
            onClick={() => handleFinalize(item.id, "send_vepara")}
            className="bg-primary text-white rounded-md p-1.5 flex items-center text-center font-semibold cursor-pointer text-sm"
            title="Vepara Gönder"
          >
            <FaMoneyBill className="mr-1" /> Vepara
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="w-full p-2 md:p-4">
  
      <div className="md:hidden">
        {data?.map((item) => (
          <PayoutCard key={item.id} item={item} />
        ))}
      </div>

      <div className="hidden md:block overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-700 text-sm">
          <thead className="">
            <tr>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm">Tutar</th>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm">Komisyon</th>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm">Bayi Adı</th>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm">Ad Soyad</th>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm">İban</th>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm">TCKN</th>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm">İşlem Tarihi</th>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm">Durum</th>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm w-[120px]">Mesaj</th>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm">Ref</th>
              <th className="px-3 py-3 text-left font-medium text-white-100 text-xs md:text-sm">İşlem</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700/30">
            {data?.map((item) => (
              <tr key={item.id} className="hover:bg-gray-700/20">
                <td className="px-3 py-2 font-medium text-white-100 text-xs md:text-sm">{item.amount_try}₺</td>
                <td className="px-3 py-2 font-medium text-white-100 text-xs md:text-sm">{item.fee_amount}₺</td>
                <td className="px-3 py-2 text-white-100 text-xs md:text-sm max-w-[120px] truncate">{item.requestor.name}</td>
                <td className="px-3 py-2 text-white-100 text-xs md:text-sm max-w-[120px] truncate">{item.holder_name}</td>
                <td className="px-3 py-2 text-white-100 text-xs md:text-sm max-w-[150px] truncate">{item.iban}</td>
                <td className="px-3 py-2 text-white-100 text-xs md:text-sm">{item.tckn}</td>
                <td className="px-3 py-2 text-white-100 text-xs md:text-sm">{moment(item.created_at).format("LLL")}</td>
                <td className="px-3 py-2 text-white-100 text-xs md:text-sm">
                  {item.status === "completed" ? (
                    <OrderIndicator color="green">Yapıldı</OrderIndicator>
                  ) : item.status === "pending_transfer" ? (
                    <OrderIndicator color="yellow">Bekliyor</OrderIndicator>
                  ) : item.status === "mico_processing" ? (
                    <OrderIndicator color="yellow">Yükleniyor</OrderIndicator>
                  ) : item.status === "failed" ? (
                    <OrderIndicator color="red">Reddedildi</OrderIndicator>
                  ) : (
                    <OrderIndicator color="red">No Data</OrderIndicator>
                  )}
                </td>
                <td className="px-3 py-2 text-white-100 w-[120px]">
                  {item.message ? (
                    <div className="whitespace-normal break-words bg-gray-800/50 p-2 rounded max-h-[50px] overflow-y-auto text-xs">
                      {item.message}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="px-3 py-2 text-white-100 text-xs md:text-sm max-w-[100px] truncate">{item.transfer_reference}</td>
                <td className="px-3 py-2 text-white-100">
                  {item.status === "pending_transfer" && (
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleFinalize(item.id, "completed")}
                        className="bg-primary text-white rounded p-1.5 flex items-center justify-center w-7 h-7"
                      
                      >
                        <MdOutlineDone size={16} />
                      </button>
                      <button
                        onClick={() => handleFinalize(item.id, "failed")}
                        className="bg-red-500 text-white rounded p-1.5 flex items-center justify-center w-7 h-7"
                      
                      >
                        <MdClear size={16} />
                      </button>
                      <button
                        onClick={() => handleFinalize(item.id, "send_vepara")}
                        className="bg-primary text-white rounded p-1.5 flex gap-1 items-center justify-center h-7"
                        title=""
                      >
                        <FaMoneyBill size={14} />
                       Vepara Gönder
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payouts;
